import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'

//import createPersistedState from "vuex-persistedstate";
import Auth from './modules/auth';
import Notificat  from './modules/notification';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: Auth,
    notificat: Notificat
  }
  //plugins: [createPersistedState()]
})
