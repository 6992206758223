export default {
    actions: {
      NOTIF_SUCCESS:({commit}, payload) => {
        return new Promise((resolve) => {
          Swal.fire({
            title: payload.poruka,
            icon: 'success',
            toast: true,
            position: payload.position,
            showConfirmButton: false,
            timer: payload.timer,
            timerProgressBar: true,
            width: payload.width,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
         }).then(function()
         {
            if(payload.action=="reload")
            {
              location.reload();
            }
            else
            {
              if(payload.action=="noreload")
              {
                //nothing to do
              }
              else
              {
                window.location.href= payload.action;
              }
            }
         })
        }) 
      },

      NOTIF_SUCCESSwithoutACTION:({commit}, payload) => {
        return new Promise((resolve) => {
          Swal.fire({
            title: payload.poruka,
            icon: 'success',
            toast: true,
            position: payload.position,
            showConfirmButton: false,
            timer: payload.timer,
            timerProgressBar: true,
            width: payload.width,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
        }) 
      },

      NOTIF_WARNING:({commit}, payload) => {
      return new Promise((resolve) => {
        Swal.fire({
          title: payload.poruka,
          icon: 'warning',
          toast: true,
          position: payload.position,
          showConfirmButton: false,
          timer: payload.timer,
          timerProgressBar: true,
          width: payload.width,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
      }) 
    },

    NOTIF_PSS:({commit}, payload) => {
    return new Promise((resolve) => {
      Swal.fire({
        title: payload.poruka,
        html:
          'You can use <b>bold text</b>, ' +
          '<a href="//sweetalert2.github.io">links</a> ' +
          'and other HTML tags',
        toast: true,
        showCloseButton: true,
        position: payload.position,
        showConfirmButton: false,
        width: payload.width,
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: '',                       // disable icon animation
        },
        hideClass: {
          popup: ''                     // disable popup fade-out animation
        }
      }).then(function(close) {
          console.log("Dovla");
      })
    })
    },

    NOTIF_LOGOUT:({commit}, payload) => {
      return new Promise((resolve) => {
        Swal.fire({
          title: payload.poruka,
          icon: 'warning',
          toast: true,
          position: payload.position,
          showConfirmButton: false,
          timer: payload.timer,
          timerProgressBar: true,
          width: payload.width,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        }).then(function()
        {
          localStorage.clear();
          window.location.href = "/Login";
        })
       }) 
    },
    SHOW_ADSIR:({commit}, payload) => {
      return new Promise((resolve) => {
        Swal.fire({
          title: '',
          html: payload.poruka,
          toast: true,
          showCloseButton: true,
          position: 'bottom',
          showConfirmButton: false,
          width: '1300px',
          background: 'rgb(255,255,255,0.7)',
          showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '',                     // disable popup animation
            icon: '',                       // disable icon animation
          },
          hideClass: {
            popup: ''                     // disable popup fade-out animation
          },
          customClass: {
            closeButton: 'custom-close'
          }
        }).then(() => {
              resolve(false);
          })
       }) 
    }

   
}
}
