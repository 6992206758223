export default {
    title: {
      report: 'REPORT\'S LIST',
      user: 'USER\'S LIST',
      superadmin: 'LIST OF PISTAMATIC AIRPORTS',
      admin_settings: 'SETTINGS',
      mailing_list: 'MAILING LIST',
      product: 'PISTAMATIC',
      edit_personal: 'EDIT PERSONAL USER INFORMATION',
      perso_basic: 'BASIC INFORMATION',
      perso_pass: 'UPDATE PASSWORD',
      add_user: 'ADD NEW USER',
      update_user: 'UPDATE INFORMATION ABOUT USER',
      airport_settings: 'UPDATE SETTING DATA FOR AIRPORT IN USE',
      log: 'LOG VIEWER:',
      contact_form: 'CONNECT WITH US',
      add_mail: 'ADD NEW MAIL INFORMATION',
      update_mail: 'EDIT MAIL INFORMATION',
      notification: 'SENDING INFORMATION',
      message_center: 'MESSAGE CENTER',
      visitor: 'LIST OF VISITORS',
      wl_stat: 'WILDLIFE STATISTICS',
      wl_stat_time: 'TIME PERIOD',
      wl_stat_animal: 'TYPE OF ANIMALS',
      wl_stat_airport: 'AIRPORT (SA)',
      duration: 'REPORT\'S DURATION',
      birdsstrike: 'PRIJAVA DOGAĐAJA UDARA PTICA',
      tracker: 'TRACKING',
      paas: 'AIRPORT ALERTING SYSTEM',
      create_alert: 'FORM FOR CREATING ALERT: ',
      update_create_alert: 'UPDATE ALERT\'S INFORMATION',
      contact_list: 'CONTACT\'S LIST',
      contact_add:'ADD NEW CONTACT',
      contact_update:'UPDATE CONTACT INFORMATION',
      paas_stopped:'ALERTS HISTORY',
      wlstat_listing:'LIST OF TIMES OF WILDLIFE REPORT CREATION',
      wlstat_summary:'WILDLIFE REPORTS SUMMARY',
      sos: 'LIST OF WILDLIFE SOS MESSAGES',
      na_animals: 'UNCATEGORIZED ANIMALS'
    },
    menu: {
        public: {
            home: 'HOME',
            about: 'ABOUT PRODUCTS',
            contact: 'CONTACT'
        },
        private: {
            reports: 'REPORTS',
            users: 'USERS',
            admin_settings: 'SETTINGS',
            mailing_list: 'MAILING LIST',
            notification: 'NOTIFICATION',
            settings: 'SETTINGS',
            runway: 'AIRPORT MAP',
            message: 'MESSAGES',
            wlstat: 'STATISTICS',
            duration: 'DURATION',
            birdsstrike: 'BIRDS STRIKE',
            wildlife: 'WLS',
            tracker: 'TMS',
            trackerLive: 'TMSlive',
            paas: 'PAAS',
            listing: 'REPORTS TIME',
            summary:'REPORTS SUMMARY',
            sos: 'SOS MESSAGES',
            na_animal: 'N/R ANIMALS'
        },
        dropdown: {
            account: 'ACCOUNT',
            help: 'HELP',
            logout: 'LOG OUT',
            visitors: 'VISITORS',
            license: 'LICENSE'
        }
    },
    general: {
        company1: 'SESTEMA',
        company2: 'DWC LLC',
        pp: 'Privacy Policy',
        mail: 'MAIL.',
        value_mail: 'info@pistamatic.com',
        mob: 'MOB.',
        value_mob: '+387 63 489 705',
        text1: 'WELCOME TO',
        text2_1: 'The PISTAMATIC application appears in the market as a powerful tool for creating all daily reports required by the airport authority efficiently and in the shortest possible time, thereby reducing the workload of operators who perform situation assessments, as well as of responsible persons in the air traffic control (ATCO’s).',
        text2_2: 'It consists of a mobile application realized on the modern tablet device, delivered with the application and the web part providing access for all other users, as defined by the airport administrator.',
        
        RWY_text1: 'PISTAMATIC RWY REPORT',
        RWY_text2: 'By using the PISTAMATIC app, there is no longer any need for the voice communication via radio channel between the airport operator and the air traffic control officer, since now the controller can have all the information at the same moment when the situation is assessed.',
        RWY_text3: 'Another important fact that should be highlighted concerns the communication that so far had to be printed, signed, distributed, and stored in the form of paper reports, which is now completely paperless via PISTAMATIC app.',
        RWY_text4: 'Now it is saved without user intervention on fully secure redundant servers, allowing users to search and open reports for five years from the moment of creation by simply viewing them through the web application.',
        RWY_text5: 'The PISTAMATIC RWYREPORT creates four reports, three of which are defined by ICAO and EASA regulations (ICAO Annex 14, Vol I, para 2.9.1 and ATT A para 6.2.9.1, ICAO Doc 9981, para 2.1 and 3.3, EASA Regulation (EU ) No 139/2014):',
        RWY_text6: 'AIRPORT DAILY SELF-INSPECTION REPORT:',
        RWY_text7: 'Sheduled report of the condition on the maneuvering areas and VISAIDs assets',
        RWY_text8: 'RCR:',
        RWY_text9: 'Report based on the new ICAO guidelines defined as GRF (Global Reporting Format)',
        RWY_text10: 'SPECIAL AIRPORT DAILY SELF-INSPECTION REPORT:',
        RWY_text11: 'Special report of the condition on the maneuvering areas and VISAIDs assets',
        RWY_text12:'REPORT ON DAMAGE: ',
        RWY_text13: 'Report of the damage on maneuvering surfaces and VISAID (this report is an add-on, which enables imaging and georeferencing of damage and automatic report generation)',
        RWY_text14: 'All the reports are associative with plenty of graphics and in line with the concept of existing paper reports, so switching to the use of tablets does not require a large burden for operators. The application guides the operator through the application by opening the required fields and allowing the entry of terms and sizes that are possible.',

        WEB_text1: 'PISTAMATIC WEB',
        WEB_text2: 'We are proud of our web app, which users especially like, because, among other things, just one look at the airport map gives them all the necessary information.',
        WEB_text3: 'The WEB part of the application provides support for the mobile app (mostly invisible to users such as log files and other information that is exchanged).',
        WEB_text4: 'Thanks to the PISTAMATIC RWYREPORT mob app, ATCO\'s and ARO service use in a simple way exclusively the web app to confirm reception of the condition of maneuvering area and the airport\'s lighting systems information, as well as to forward RCR information in the form of SNOWTAM to the AMHS network.',
        WEB_text5: 'For the purpose of effective protection of the air operations at the airport, the web app creates numerous graphic diagrams, which enable airport operators to efficiently prevent the appearance of WL (WildLife) on airport maneuvering areas.',

        WL_text1: 'PISTAMATIC WL REPORT',
        WL_text2: 'Recently, the PISTAMATIC family has been extended for the new WLR (WildLife Report) app.',
        WL_text3: 'Based on experience and observing the existing solutions and their shortcomings, we created an application format that should meet all the needs of the airport, both for the current information from manoeuvring areas and the multitude of statistical information that the web part offers.',
        WL_text4: 'Operators can now in a simple PISTAMATIC way fill out the report form with all the data that are important for the effective wildlife management.',
        WL_text5: 'The WLR app is highly adapted in all segments according to the each user, i.e. local conditions and airport environment.',
        WL_text6: 'For the WLR web segment, it is also important to emphasize that all the information gathered from the reports are used for the powerful statistical analysis through the illustrative graphs.',
        
        text13: 'INTERCONNECTED REPORTS',
        text14: 'The reports are interconnected and, if necessary, the data from one report is automatically loaded into another by the application. Reports are sent to predefined addresses. An integral part of an app is web part that allows user to manage the application as well as access, store and search reports.'
    },
    contact_form: {
        name: 'FULL NAME',
        mail: 'EMAIL',
        airport: 'AIRPORT NAME',
        message: 'MESSAGE'
    },
    login: {
        text1: 'Welcome to',
        text2: 'APPLICATION',
        username: 'USERNAME',
        pass: 'PASSWORD'
    },
    button: {
        search: 'SEARCH',
        reset: 'RESET',
        send: 'SEND',
        preview: 'PREVIEW',
        delete: 'DELETE',
        view: 'VIEW',
        log: 'LOG',
        edit: 'EDIT',
        signin: 'SIGN IN',
        website: 'WEBSITE',
        change: 'CHANGE',
        cancel: 'CANCEL',
        save: 'SAVE',
        back: 'BACK',
        contact: 'CONTACT',
        login: 'LOGIN',
        get: 'GET IT NOW',
        send_mes: 'SEND MESSAGE',
        rcr: 'RCR',
        reportI: 'ADSIR',
        full: 'FULL',
        report_wlr: 'WLR'
    },
    pagination: {
        next: 'NEXT',
        previous: 'PREVIOUS'
    },
    message: {
        license: {
            m1: 'Your license expires on: '
        },
        user: {
            m1: 'You successfully send user information...',
            m2: 'Problem to send information about password! Please, contact administrator!',
            m3: 'Please, add minimal one search request!',
            m4: 'User deleted successfully',
            m5: 'User temporarly deleted successfully'
        },
        report: {
            m1: 'Problem to preview this report! Please, contact administrator...',
            m2: 'Report deleted successfully',
            m3: 'Please, add minimal one search request!',
            m4: 'You don\'t have permission to change status of field!'
        },
        superadminsettings: {
            m1: 'Please, fill all fields!',
            m2: 'Airport added for using successfully',
            m3: 'This airport has already been added!',
            m4: 'Please, choose airport for seraching!',
            m5: 'ADD ERROR LOG FILE!'
        },
        mailing_list: {
            m1: 'Mail information deleted successfully',
            m2: 'Please, choose airport!'
        },
        login: {
            m1:'You enter incorrect usarname/password or you dont have permission to access. Please, contact your administrator...',
            m2:'Please, contact administrator'
        },
        personal: {
            m1: 'This username already exists, please, change value...',
            m2: 'Personal basic information updated successfully',
            m3: 'Your current password is wrong. Please, enter again...',
            m4: 'Password updated successfully',
            m5: 'Problem to send information about update password! Please, contact administrator!',
            m6: 'Value for new password and confirm password must be the same. Please, enter again...'
        },
        usermanipulation: {
            m1: 'This username or email already exists, please, change value...',
            m2: 'User updated successfully',
            m3: 'This username or email already exists, please, change value...',
            m4: 'User added successfully',
            m5: 'Problem to send login information for new user! Please, contact administrator!'
        },
        airport_settings: {
            m1: 'Using airport updated successfully'
        },
        log: {
            m1: 'Successfully deleted log'
        },
        contact_form: {
            m1: 'YOU SEND MESSAGE SUCCESSFUL...'
        },
        mail_manipulation: {
            m1: 'Mail information updated successfully',
            m2: 'New mail information inserted successfull'
        },
        notification: {
            m1: 'Email configuration updated successfully',
            m2: 'You want to save option: NOT SEND EMAIL NOTIFICATION!'
        },
        message_center: {
            m1: 'Email send successfully'
        },
        airport_map: {
            m1: 'Problem to preview this report! Please, contact administrator...',
            m2: 'RCR is expired!'
        },
        wl: {
            m1: 'Please, choose type of animal and time period!',
            m2: 'NO DATA FOR SEARCHED ANIMAL'
        },
        birdsstrike: {
            m1: 'Birds Strike report sent successfully'
        }
    },
    report: {
        search: {
            date: 'DATE',
            type:'REPORT TYPE',
            airport:'AIRPORT (ICAO SIGN)'
        },
        table: {
            type: 'TYPE',
            airport: 'AIRPORT',
            operator: 'OPERATOR',
            date: 'DATE',
            date_approved: 'ATC VIEW TIME',
            approvedby: 'APPROVED BY',
            approved: 'APPROVED',
            options: 'OPTIONS',
            responsible: 'ACK'
        },
        approve: {
            twr: 'ATC TWR user',
            aro: 'ATC ARO user'
        },
        duration: {
            m1: 'Please, add period for search!',
            m2: 'Defined period is wrong! Please, correct...'
        },
    },
    user: {
        add: 'ADD NEW USER',
        search: {
            name:'FULL NAME',
            airport:'AIRPORT (ICAO SIGN)'
        },
        table: {
            name: 'FULL NAME',
            username: 'USERNAME',
            role: 'ROLE',
            airport: 'AIRPORT',
            login: 'LAST LOGIN',
            active: 'ACTIVE',
            options: 'OPTIONS'
        }
    },
    superadminsettings: {
        add: 'ADD NEW AIRPORT',
        search: {
            date: 'DATE',
            type:'REPORT TYPE',
            airport:'ICAO LIST OF AIRPORTS',
            config:'CONFIGURATION',
            using_airport: 'PISTAMATIC AIRPORTS',
            rwy:'RWY APP',
            wl:'WL APP',
            atc:'ATC ROLE',
            reporting: 'EMAIL NOTIFICATION'
        },
        notification: {
            first: 'NOT SEND',
            second: 'SEND REP.',
            third: 'SEND INFO'
        },
        table: {
            airport: 'AIRPORT',
            added: 'LICENSE',
            version_RWY: 'VERSION RWY',
            version_WL: 'VERSION WL',
            remarks: 'REMARKS',
            rwyapp: 'RWY',
            wlapp: 'WL',
            atc_role: 'ATC',
            notification: 'REPORTING',
            options: 'OPTIONS',
            configuration: 'CONFIG'
        }
    },
    mailing_list: {
        add: 'ADD NEW MAIL',
        search: {
            airport:'AIRPORT (ICAO SIGN)'
        },
        table: {
            airport: 'AIRPORT',
            type: 'TYPE',
            label: 'LABEL NAME',
            mail: 'MAIL ADDRESS',
            options: 'OPTIONS'
        }
    },
    personal: {
        name: 'FULL NAME',
        username: 'USER NAME',
        mail: 'EMAIL',
        current_pass: 'CURRENT PASSWORD',
        new_pass: 'NEW PASSWORD',
        confirm_pass: 'CONFIRM PASSWORD'
    },
    usermanipulation: {
        name: 'FULL NAME',
        username: 'USER NAME',
        mail: 'EMAIL',
        role: 'ROLE',
        airport: 'AIRPORT',
        config: 'CONFIGURATION',
        rwy: 'RWY APPLICATION',
        wl: 'WILDLIFE APPLICATION',
        atc: 'ATC ROLE'
    },
    airport_settings: {
        airport: 'AIRPORT',
        version_RWY: 'RWY VERSION',
        version_WL: 'WL VERSION',
        remarks: 'REMARKS'
    },
    log: {
        table: {
            message: 'MESSAGE',
            added: 'ADDED',
            options: 'OPTIONS'
        }
    },
    mail_manipulation: {
        airport: 'AIRPORT',
        label: 'LABEL NAME',
        mail: 'MAIL ADDRESS',
        type: 'TYPE OF MAILING LIST'
    },
    notification: {
        title: 'EMAIL NOTIFICATION FOR AIRPORT ',
        first: 'NOT SENDING ',
        first_1: 'EMAIL NOTIFICATION',
        second: 'SENDING ',
        second_1: 'EMAIL NOTIFICATION WITH REPORT',
        third: 'SENDING ',
        third_1: 'EMAIL NOTIFICATION WITHOUT REPORT',
        mailing:'MAILING LIST'
    },
    message_center: {
        airport: 'AIRPORT',
        title: 'TITLE',
        text: 'TEXT',
        all: 'ALL AIRPORTS',
        table: {
            airport: 'AIRPORT',
            date: 'DATE/TIME',
            title: 'TITLE',
            text: 'MESSAGE',
            send: 'STATUS'
        }
    },
    airport_map: {
        radio1: 'RCR',
        radio2: 'ADSIR'
    },
    visitor: {
        table: {
            text: 'COUNTRY/CITY',
            date: 'DATE/TIME'
        }
    },
    wl_stat: {
        typeanimal: 'TOTAL NUMBER OF ANIMAL\'S TYPE',
        typeb: 'TOTAL NUMBER OF BIRDS',
        typem: 'TOTAL NUMBER OF MAMMALS',
        typenob: 'NUMBER OF BIRD SPECIES RECORDED',
        typenom: 'NUMBER OF MAMMAL SPECIES RECORDED',
        timeb:'TIME WHEN BIRD SPECIES ARE RECORDED',
        timem:'TIME WHEN MAMMAL SPECIES ARE RECORDED',
        monthb:'TIME WHEN BIRD SPECIES RECORDED BY MONTH',
        monthm:'TIME WHEN MAMMALS SPECIES RECORDED BY MONTH',
        wind:'BIRD SPECIES RECORDED IN CORRELATION WITH WIND DIRECTION ',
        activity:'BIRD SPECIES ACTIVITY ',
        measureB:'MEASURES USED FOR BIRDS',
        resultB:'SUCCESS OF IMPLEMENTED MEASURES FOR BIRDS',
        measureM:'MEASURES USED FOR MAMMALS',
        resultM:'SUCCESS OF IMPLEMENTED MEASURES FOR MAMMALS',
        measureBirds:'MEASURES USED vs BIRD SPECIES ',
        measureMammals:'MEASURES USED vs MAMMAL SPECIES ',
        generalGridB: 'LOCATION WHERE BIRDS ARE RECORDED',
        generalGridM: 'LOCATION WHERE MAMMALS ARE RECORDED',
        gridB: 'LOCATION WHERE BIRD ARE RECORDED',
        gridM: 'LOCATION WHERE MAMMAL ARE RECORDED',
        search: {
            date: 'DATE',
            period:'PERIOD',
            birds:'BIRDS',
            mammals: 'MAMMALS',
            airport: 'AIRPORT'
        }
    },
    duration: {
        table: {
            date: 'DATE',
            duration: 'DURATION'
        }
    },
    birdsstrike: {
        dogadjaj: 'DOGAĐAJ',
        dogadjaj_1:'UDAR PTICE/DRUGE ŽIVOTINJE',
        dogadjaj_2: 'PRIJAVLJEN UDAR PTICE/DRUGE ŽIVOTINJE',
        dogadjaj_3: 'MOGUĆI UDAR PTICE/DRUGE ŽIVOTINJE',
        dogadjaj_4: 'PRIJAVLJENO PRISUSTVO PTICE/DRUGE ŽIVOTINJE',
        source: 'IZVOR INFORMACIJE O DOGAĐAJU',
        source_1: 'AKL',
        source_2: 'ŠEF SMJENE SZO',
        source_3: 'REDOVAN OBILAZAK',
        source_4: 'DRUGO',
        ruta:'RUTA OD - DO',
        operator: 'OPERATOR',
        flight_no: 'BROJ LETA',
        type_airplane: 'TIP VAZDUHOPLOVA',
        ref_no: 'REF. BROJ OBRASCA',
        date: 'DATUM',
        time: 'VRIJEME',
        PartOfDay: 'DOBA DANA',
        Cloudy: 'OBLAČNOST',
        Drops: 'PADAVINE',
        zora:'ZORA',
        jutro:'JUTRO',
        dan:'DAN',
        noc: 'NOĆ',
        sumrak: 'SUMRAK',
        vedro:'BEZ OBLAKA',
        mala_obl: 'MALA OBLAČNOST',
        puno_obl: 'PUNO OBLAKA',
        kisa: 'KIŠA',
        magla: 'MAGLA',
        snijeg: 'SNIJEG',
        faza: 'FAZA LETA',
        slijetanje: 'SLIJETANJE',
        polijetanje: 'POLIJETANJE',
        taksiranje: 'TAKSIRANJE',
        efect: 'UTICAJ NA LET',
        obilazak: 'OBILAZAK',
        nema: 'NEMA',
        prekinuto: 'PREKINUTO POLIJETANJE',
        predostr: 'SLIJETANJE IZ PREDOSTROŽNOSTI',
        gasenje: 'GAŠENJE MOTORA',
        drugo: 'DRUGO',
        redovni: 'REDOVNI',
        vanredni: 'VANREDNI',
        pot_kolizija: 'POTVRĐENA KOLIZIJA',
        ne_kolizija: 'NEPOTVRĐENA KOLIZIJA',
        species: 'NAZIV ŽIVOTINJE',
        broj: 'BROJ OSTATAKA',
        velicina: 'VELIČINA',
        foto: 'FOTO',
        lokacija: 'LOKACIJA / POZICIJA / PSS - AP',
        opis: 'OPIS DOGAĐAJA',
        primijeceno: 'EVIDENTIRANO PRISUSTVO PTICA / DRUGIH ŽIVOTINJA PRIJE OPERACIJA A/C',
        akl: 'OBAVIJEŠTEN AKL',
        evidencija: 'VRSTA PTICE I LOKACIJA',
        rastjerivanje: 'VRŠENO RASTJERIVANJE',
        vrsta_zivotinje: 'VRSTA ŽIVOTINJE',
        ptica: 'PTICA',
        dr_ziv: 'DRUGA ŽIVOTINJA',
        slika_pro_ost: 'SLIKA PRONAĐENIH OSTATAKA',
        slika_tragova: 'SLIKA TRAGOVA NA VAZDUHOPLOVU',
        mala: 'MALA',
        srednja: 'SREDNJA',
        velika: 'VELIKA',
        da: 'DA',
        ne: 'NE',
        broj_evidentirano: 'BROJ',
        lokacija_evidentirano: 'LOKACIJA',
        velicina_izmjerena: 'IZMJERENA VELIČINA',
        mjere: 'NAČIN I EFIKASNOST RASTJERIVANJA PTICA/DRUGIH ŽIVOTINJA'
    },
    listing:{
        total:'TOTAL NUMBER OF GENERATED REPORTS:',
        table: {
            date: 'DATE',
            times:'TIMES',
            count:'COUNT'
        }
    },
    sos: {
        total: 'TOTAL NUMBER OF GENERATED WILDLIFE SOS MESSAGES:',
        table: {
            date: 'DATE, TIME',
            animals:'ANIMALS',
            location:'LOCATION',
            gridno:'GRID NO',
            description:'DESCRIPTION',
            operator:'OPERATOR'
        }
    },
    wlr_summary:{
        total:'UKUPAN BROJ GENERISANIH IZVJEŠTAJA:',
        table: {
            date: 'DATUM',
            interval:'VRIJEME OSMATR.',
            location:'LOKACIJA OSMATR.',
            gridNo:'KVADRANT',
            species:'PTICA/SISAR',
            number:'BROJ',
            measures:'MJERE',
            movement:'PRAVAC RASTJER.',
            description:'NAPOMENA'
        }
    },
    na_animals:{
        total:'TOTAL NUMBER OF UNCATEGORIZES ANIMALS IN LAST 1 YEAR:',
        table: {
            animals: 'ANIMALS',
            count:'COUNT'
        }
    },
}