import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import mne from './mne'

Vue.use(VueI18n)

// Ready translated locale messages
const messages = {
  en: en,
  mne: mne
}

const i18n = new VueI18n({
  locale: 'en', // set locale
  //locale: localStorage.getItem('Local'),
  //locale: process.env.VUE_APP_JEZIK,
  messages // set locale messages
})

export default i18n